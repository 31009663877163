import http, { setTokenInHeader } from "./httpService";

const tokenKey = "token";

export async function login(loginDetails) {
  const { data } = await http.post("/login/", loginDetails);
  setToken(`token ${data.tokens}`);
  setTokenInHeader(`token ${data.tokens}`);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function setToken(token) {
  localStorage.setItem(tokenKey, token);
}

export function getToken() {
  return localStorage.getItem(tokenKey);
}

export function sendConfirmationEmail() {
  return http.get("/auth/sendConfirmationEmail");
}

export function confirmEmail(token) {
  return http.get(`/auth/emailConfirmation/${token}`);
}

export function sendForgotPasswordEmail(emailAddress) {
  return http.get(`/auth/forgotPassword/${emailAddress}`);
}

export function resetPassword(token, password) {
  return http.post(`/auth/resetPassword`, { token, password });
}
