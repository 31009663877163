import React from "react";
import Joi from "joi";

import Box from "@mui/material/Box";
import Input from "@mui/material/TextField";
import Button from "@mui/material/Button";
import toast from "react-hot-toast";
import { sendContactRequest } from "../services/userService";
import useForm from "../hooks/useForm";
import Footer from "./Footer";
import Navbar from "./Navbar";

const validationSchema = {
  name: Joi.string().max(1500).required().label("Name"),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email Address"),
  message: Joi.string().max(255).required(true).label("Message"),
};

function Contact() {
  const {
    values,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    handleFocus,
  } = useForm({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema,
    doSubmit,
  });

  async function doSubmit() {
    try {
      const data = { ...values };
      await sendContactRequest(data);
      toast.success("Sent Successfully");
    } catch (err) {
      if (err?.response?.status >= 400 && err?.response?.status < 500) {
        toast.error(err?.response?.data?.detail?.toString());
      }
      console.log(err);
    }
  }

  return (
    <>
      <Navbar />
      <div>
        <div style={{ textAlign: "center", marginTop: 80 }}>
          <h1 style={{ fontWeight: 200 }}>CONTACT US TODAY</h1>
          <p style={{ fontWeight: 200 }}>
          <h4 style={{ fontWeight: 200 }}>
          We are committed to providing you with the best possible assistance and ensuring that all your questions are answered accurately and efficiently.         
          </h4>
            
          </p>
          <p>
          For personalized support and prompt answers to your inquiries, please provide us with as many details as possible when contacting us. 
          </p>
        </div>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box pt={4} pb={3} px={3} width={"50%"}>
            <Box component="form" role="form" onSubmit={handleSubmit}>
              <Box mb={2}>
                <Input
                  label="Name"
                  name="name"
                  value={values.name}
                  error={!!errors.name}
                  helperText={errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  required
                  fullWidth
                />
              </Box>
              <Box mb={2}>
                <Input
                  type="email"
                  label="Email"
                  name="email"
                  value={values.email}
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  required
                  fullWidth
                />
              </Box>
              <Box mb={2}>
                <Input
                  label="Message"
                  name="message"
                  value={values.message}
                  error={!!errors.message}
                  helperText={errors.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  required
                  fullWidth
                  multiline
                  minRows={3}
                />
              </Box>
              <Box mt={4} mb={1}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer />
      </div>
    </>
  );
}

export default Contact;
