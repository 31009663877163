import { Button } from "@mui/material";
import React from "react";
// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import Footer from "./Footer";
import Navbar from "./Navbar";

function AboutCard({ iconPath, heading, text }) {
  return (
    <div className="about-card">
      <div className="about-icon">
        <img src={iconPath} alt={heading} />
      </div>
      <h2>{heading}</h2>
      <p>{text}</p>
    </div>
  );
}



function ServiceCard({ imgPath, heading, text, serviceId }) {
  return (
    <div className="service-card">
      <div className="service-img">
        <img className="service-img" src={imgPath} alt={heading} />
      </div>
      <div className="h2">
        <h2 id="services">{heading}</h2>
      </div>
      <div className="service-p">
        <p>{text}</p>
      </div>
      {/* <Link to={`/${serviceId}`} >         <Button variant="outlined">Learn More</Button>       </Link> */}
      <a href="https://www.iab.ai/erp/erp-subscribe" >
          <Button variant="contained">Subscribe</Button>
      </a>

      <a href={`/${serviceId}`} >
          <Button variant="outlined">Learn More</Button>
      </a>

    </div>
  );
}

function Home() {
  return (
    <div>
      <Navbar />
      <div className="banner">
        <div style={{ width: "70%" }}>
          <h1>Transform Your Business with Our IT Solutions</h1>
          <h3>Empower your business with our innovative IT solutions, designed to streamline operations, increase efficiency and help you achieve your goals.</h3>
          <div className="banner-btns">
            <Link to="#services">
              <Button variant="outlined" color="secondary" sx={{ marginRight: 2 }}>
                Services
              </Button>
            </Link>
            <a href="https://www.iab.ai/erp/erp-subscribe">
            <Button variant="contained" sx={{ marginRight: 2 }}>
                Subscribe
              </Button>      
                    </a>
          </div>
        </div>
      </div>
      <div className="about">
        <h1>Our Mission</h1>
        <h3>
        At IABAI, our mission is to empower businesses with cutting-edge IT solutions that drive growth, improve productivity, and enhance customer satisfaction.
        </h3>
        
        <h1>Why Choose Us?</h1>
        <h3>
        
        <p>
        Our team of highly skilled professionals in IT, Automation, Business Management, Artificial Intelligence, and Project Management is dedicated to providing 
        top-level support and guidance to help you achieve your project goals.         
        </p>
        <p>
        At IABAI, we pride ourselves on offering a wide range of services to meet your business needs, including software and website development, 
        quality assurance, data analysis, migration to the Cloud, Payment integration and ChatBots powered by LLMs, privates or public.          
        </p>
        <p>
        Whether you require assistance with a specific project or ongoing support, you can trust that your project is in capable hands with our team of experts.   
        </p>
        <p>
        When accessing our SaaS, on all subscriptions models, you benefit for free from automatic TSL installation and rolling updates (using Let'sEncrypt certificates) or you can communicate your own, to make your website rpotected with HTTPS schema.   
        </p>

        </h3>

        <h1> Get in Touch</h1>
        <h3>
        Ready to take your business to the next level?<br /> 
        <a href="/f/contact"> Contact us today </a>  to learn more about our IT solutions and how we can help you achieve your goals.

        </h3>

        <div className="about-cards">
          <AboutCard
            iconPath="/assets/card1.svg"
            heading="SaaS"
            text="Our Software-as-a-Service solutions are designed to help businesses of all sizes streamline their operations and increase productivity. With our easy-to-use cloud-based software, you can access your data from anywhere and enjoy hassle-free updates and maintenance."
          />
          <AboutCard
            iconPath="/assets/card2.svg"
            heading="Custom Development"
            text="Our team of expert developers can create custom software and web applications tailored to your business needs. From e-commerce platforms to mobile apps, we use the latest technologies to deliver high-quality solutions that help you achieve your goals."
          />
          <AboutCard
            iconPath="/assets/card3.svg"
            heading="IT Consulting"
            text="Our experienced consultants provide a range of services, including CTO-level guidance, project management, and cloud migration. We work closely with your team to understand your business needs and provide tailored solutions that help you optimize your IT infrastructure and achieve your objectives."
          />
        </div>
      </div>
      <div className="service" id="services">
        <div className="service-cards">
          <ServiceCard
            imgPath="/assets/erp-help.jpeg"
            heading="ENTERPRISE RESOURCE PLANNING"
            text="Introducing our ERP SaaS service that consolidates all company aspects, including sales, HR, stock, finance, and project management, into one platform, streamlining operations."
            serviceId="erp"
          />
          <ServiceCard
            imgPath="/assets/cloud-storage.jpeg"
            heading="CLOUD STORAGE"
            text="Introducing our cloud storage SaaS service - access, sync, and share files securely with powerful collaboration capabilities across desktop, mobile, and web interfaces."
            serviceId="erp"
          />
          <ServiceCard
            imgPath="/assets/service3.jpeg"
            heading="EMAIL SYSTEM"
            text="Introducing our dedicated email system with anti-spam and anti-virus protection. 
            Accessible from any client or web interface, with full control over domains, aliases, mailing lists, auto-reply, auto-forward and more."
            serviceId="erp"
          />
        </div>
        {/* <h1>IT SOLUTIONS</h1>
        <h2>Services ready within minutes, customized to your company</h2> */}
      </div>
      <Footer />
    </div>
  );
}

export default Home;
