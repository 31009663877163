import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";

import NavbarLink from "./NavbarLink";
import NavbarMobile from "./NavbarMobile";

function Navbar({ transparent, light }) {
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const openMobileNavbar = ({ currentTarget }) =>
    setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);

  useEffect(() => {
    // A function that sets the display state for the NavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < 990) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /**
     The event listener that's calling the displayMobileNavbar function when
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <Container>
      <Box
        py={1}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 10 }}
        width="100%"
        borderRadius="lg"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        // position="absolute"
        // left={0}
        // zIndex={3}
        sx={{
          backgroundColor: transparent ? "transparent" : "#ffffffcc",
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        }}
      >
        <Box
          component={Link}
          to="/"
          py={transparent ? 1.5 : 0.75}
          lineHeight={1}
          pl={{ xs: 0, lg: 1 }}
          sx={{ textDecoration: "none" }}
        >
          <Typography
            variant="button"
            fontWeight="bold"
            color={light ? "white" : "dark"}
            fontSize={18}
            imgPath="/assets/iabai_logo.png"

          ><Box sx={{ width: 1 }}> 
             <img src="/assets/iabai_logo_small.png" alt="IABAI" />
            </Box>
            
          </Typography>
        </Box>
        <Box color="inherit" display={{ xs: "none", lg: "flex" }} m={0} p={0}>
          {/* <NavbarLink name="home" route="/" light={light} /> */}
          {/* <NavbarLink name="contact" route="/contact" light={light} /> */}
          <a href="/f/contact" className={light ? "navbar-link-light" : "navbar-link"}>
              Contact
          </a>
          {/* <NavbarLink icon="account_circle" name="sign up" route="/signup" />
          <NavbarLink icon="key" name="sign in" route="/signin" /> */}
        
        </Box>
        {mobileView && (
          <Box sx={{ cursor: "pointer" }} onClick={openMobileNavbar}>
            <MenuIcon />
          </Box>
        )}
      </Box>
      {mobileView && (
        <NavbarMobile open={mobileNavbar} close={closeMobileNavbar} />
      )}
    </Container>
  );
}

// Setting default values for the props of Navbar
Navbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the Navbar
Navbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default Navbar;
