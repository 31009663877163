import React from "react";
import { Outlet } from "react-router-dom";
// import { getToken } from "../services/authService";

const PublicRoute = () => {
  // const auth = getToken();
  // const navigate = useNavigate();

  // React.useEffect(() => {
  //   if (auth) {
  //     navigate("/");
  //   }
  // }, [auth, navigate]);

  return <Outlet />;
};

export default PublicRoute;
