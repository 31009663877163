import Joi from "joi";
import { useParams, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Input from "@mui/material/TextField";
import Button from "@mui/material/Button";
import toast from "react-hot-toast";
import useForm from "../hooks/useForm";
import { addService, getOptions } from "../services/servicesService";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useEffect, useState } from "react";
function Service() {
  const navigate = useNavigate();
  const { service } = useParams();
  // console.log(service)
  // debugger
  const [dynamicValidation,setDynamicValidation]=useState({})
  const [initialValues,setInitialValues]=useState({})
  // const validationSchema = {
    
  //   // company_name: Joi.string().max(255).required().label("Company Name "),
  //   // company_url: Joi.string().max(255).required().label("Company URL"),
  //   // email: Joi.string()
  //   //   .email({ tlds: { allow: false } })
  //   //   .required()
  //   //   .label("Email Address"),
  // };

  const {
    values,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    handleFocus,
  } = useForm({
    initialValues,
    validationSchema:dynamicValidation,

    doSubmit,
  });

  async function doSubmit() {
    try {
      const data = { ...values };
      // console.log(data,"ONSUBMIT")
      await addService(service, data);
      toast.success("Company created successfully");
      navigate("/");
    } catch (err) {
      if (err?.response?.status >= 400 && err?.response?.status < 500) {
        toast.error(err?.response?.data?.detail?.toString());
      }
     // console.log(err);
    }
  }

  const [formData,setFormData]=useState()
  
  const fecthOptions=async(service)=>{
      const {data} =await getOptions(service)
      setFormData(data?.actions?.POST)
      let fields=data?.actions?.POST
      let tempValidation={}
      let tempInitialValues={}
      Object.keys(fields).forEach(x=>{

              if(x!=="id"){
                if(x!=="id"){
            tempInitialValues[x]=""

                if(fields[x].type==="integer")
        tempValidation[x]=Joi.number().max(255).optional().label(fields[x].label)

              else if(fields[x].type==="email")
        tempValidation[x]=Joi.string().email({ tlds: { allow: false } }).optional().label(fields[x].label)
              // else if(fields[x].type==="date")
              else
              tempValidation[x]=Joi.string().max(255).optional().label(fields[x].label)
              }
            }

        })

        console.log(tempInitialValues,"tempInitialValues")
        setDynamicValidation({...tempValidation})
        setInitialValues({...tempInitialValues})


   //   console.log(data?.actions?.POST,"Response")
  }



  useEffect(() => {
    fecthOptions(service)
  }, [])
  
  // console.log(Object.keys(formData),"formData")

  return (
    <>
    <Navbar />
    <div>
       
    <Box
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box pt={4} pb={3} px={3} width={360}>
        <Box component="form" role="form" onSubmit={handleSubmit}>
          <Box mb={6} textAlign="center">
            <h3>Add your company</h3>
          </Box>
          
          {
            
            formData && Object.keys(formData).map(x=>{
              if(x!=="id")
              return(
                <Box mb={2}>
                <Input
                  label={formData[x].label}
                  name={x}
                  // value={initialValues[x]}
                  value={values[x]}
                  error={!!errors.company_name}
                  helperText={errors.company_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  required={formData[x].required}
                  type={formData[x].type==="integer"?"number":formData[x].type}
                  fullWidth
                />
              </Box>
              )
              return true
            })
          }
         
          {/* <Box mb={2}>
            <Input
              label="Company URL"
              name="company_url"
              value={values.company_url}
              error={!!errors.company_url}
              helperText={errors.company_url}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              required
              fullWidth
            />
          </Box>
          <Box mb={2}>
            <Input
              type="email"
              label="Email"
              name="email"
              value={values.email}
              error={!!errors.email}
              helperText={errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              required
              fullWidth
            />
          </Box> */}
          <Box mt={4} mb={1}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
    </div>
    <Footer />
  </>


  );
}

export default Service;
