import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../components/Home";
// import Erp from "../components/Erp";
import Signup from "../components/Signup";
import Signin from "../components/Signin";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Service from "../components/Service";
import Contact from "../components/Contact";
import ContactList from "../components/ContactList"
function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PublicRoute />}>
          {/* <Route path="signin" element={<Signin />} /> */}
          {/* <Route path="signup" element={<Signup />} /> */}
          {/* <Route path="contact" element={<Contact />} /> */}
          
          <Route index element={<Home />} />
          {/* <Route path='erp' component={() => { 
                  window.location.href = 'https://www.iab.ai/erp'; 
                  return null;
              }}/> */}
          </Route>  
        {/* <Route path="services" element={<PrivateRoute />}> */}
        <Route path="services" element={<PublicRoute />}>
          <Route path=":service" element={<Service />} />
        </Route>
        {/* <Route path="list" element={<PrivateRoute />}> */}
          {/* <Route path="contacts" element={<ContactList />} /> */}
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
