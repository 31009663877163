import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function NavbarLink({ name, route, light }) {
  return (
    <>
      <Box
        component={Link}
        to={route}
        mx={1}
        p={1}
        display="flex"
        alignItems="center"
        sx={{ cursor: "pointer", userSelect: "none", textDecoration: "none" }}
      >
        <Typography
          variant="button"
          fontWeight="bold"
          color={light ? "white" : "dark"}
          textTransform="capitalize"
          sx={{
            width: "100%",
            lineHeight: 0,
            fontSize: 16,
          }}
        >
          {name}
        </Typography>
      </Box>
    </>
  );
}

// Typechecking props for the NavbarLink
NavbarLink.propTypes = {
  name: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  light: PropTypes.bool.isRequired,
};

export default NavbarLink;
