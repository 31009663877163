import Joi from "joi";
import { Link, useNavigate, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/TextField";
import Button from "@mui/material/Button";
import toast from "react-hot-toast";
import { login } from "../services/authService";
import useForm from "../hooks/useForm";
import Navbar from "./Navbar";
import Footer from "./Footer";

// Images
// const bgImage = "/assets/bg-sign-in-basic.jpeg";

function Signin() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const validationSchema = {
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email Address"),
    password: Joi.string().min(6).max(255).required().label("Password"),
  };

  const {
    values,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    handleFocus,
  } = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    doSubmit,
  });

  async function doSubmit() {
    try {
      const userData = { ...values };
      await login(userData);
      navigate(state?.from ? state.from.pathname : "/");
    } catch (err) {
      if (err?.response?.status >= 400 && err?.response?.status < 500) {
        toast.error(err?.response?.data?.detail?.toString());
      }
      console.log(err);
    }
  }

  return (
    <>
    <Navbar />
    <div>
       
    <Box
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box pt={4} pb={3} px={3} width={360}>
        <Box component="form" role="form" onSubmit={handleSubmit}>
          <Box mb={6} textAlign="center">
            <h1>Sign In</h1>
          </Box>
          <Box mb={2}>
            <Input
              type="email"
              label="Email"
              name="email"
              value={values.email}
              error={!!errors.email}
              helperText={errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              required
              fullWidth
            />
          </Box>
          <Box mb={2}>
            <Input
              type="password"
              label="Password"
              name="password"
              value={values.password}
              error={!!errors.password}
              helperText={errors.password}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              required
              fullWidth
            />
          </Box>
          <Box mt={4} mb={1}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              sign in
            </Button>
          </Box>
          <Box mt={3} mb={1} textAlign="center">
            <Typography variant="button" color="text">
              Don&apos;t have an account?{" "}
              <Typography
                component={Link}
                // to="/auth/signup"
                to="/signup"
                variant="button"
                color="info"
                fontWeight="medium"
              >
                Sign up
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
    </div>
    <Footer />
  </>
  );
}

export default Signin;
