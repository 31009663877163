import Axios from "axios";
import toast from "react-hot-toast";

// const http = Axios.create({
//   // baseURL: "https://fivauth.herokuapp.com/api",
//   // baseURL: "https://be.lhw.io/api",
//   // baseURL: "10.108.112.91:5005/api", 
//   // baseURL: "http://iabai-backend.devel.svc.cluster.local"
//    baseURL:  `${process.env.REACT_APP_API_URL}/api`
//   // baseURL: `${process.env.IABAI_API_SERVICE_PORT_5005_TCP_ADDR}:${process.env.IABAI_API_SERVICE_PORT_5005_TCP_PORT}/api`
// //  baseURL: "http://iabai-api-service.iabai.svc.cluster.local:5005/api"
// // baseURL: "http://localhost:5000/api"
// //   baseURL: "https://api.iab.ai/api"
// //  baseURL: "http://iabai-api-service:5005/api"


//   });

const http = Axios.create({
  baseURL: "http://iabai-api-service:5005/api"
});


http.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    console.log("Unexpected Error", error);
    toast.error("An Unexpected Error Occured!");
  }

  return Promise.reject(error);
});

export function setTokenInHeader(token) {
  if (token) {
    http.defaults.headers.common["authorization"] = token;
  } else {
    delete http.defaults.headers.common["authorization"];
  }
}

export default http;
