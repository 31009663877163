import React from "react";



function Footer() {
  return (
    <div className="footer-wrapper">
      <div className="footer">
        <div className="footer-section hidden">
          <h3>ABOUT IABAI</h3>
          <p>IABAI, your partner of choice for your IT Solutions</p>
        </div>
       
        <div className="footer-section">
          <h3> Contact us </h3>
          <div className="footer-social ">
                <a className="footer-link" href="mailto:info@iab.ai?subject=Request for information about your services" target="_blank" rel="noreferrer">
                  <img className="rounded-lg" src="/assets/email_icon.png" alt="E-Mail" title="Send an E-Mail" /> 
                </a>

                <a className="footer-link" href="https://wa.me/33629791730?text=Hi,%20I'm%20interested%20in%20IABAI%20services.%20in%20particular,I%20would%20like%20to%20know%20more%20about:%20" target="_blank" rel="noreferrer" >
                    <img className="rounded-lg" src="/assets/whatsapp_icon.png" alt="Whatsapp" title="Whatsapp"/> 
                </a>

          </div>
        </div>

        <div className="footer-section">
            <div className="footer-social">
              <h3>Social</h3>
                <a className="footer-link" href="https://twitter.com/iabai_" target="_blank" rel="noreferrer" >
                  <img className="rounded-lg" src="/assets/twitter_icon.png" alt="Twitter" title="Twitter"/> 
                </a>
                <a className="footer-link" href="https://www.linkedin.com/company/iab-ai" target="_blank" rel="noreferrer">
                  <img className="rounded-lg" src="/assets/linkedin_icon.png" alt="linkedin" title="Linkedin" /> 
                </a>
            </div>
            <div className="footer-social hidden">
              
                <a className="footer-link" href="https://t.me/IabaiT" target="_blank" rel="noreferrer">
                  <img className="rounded-lg" src="/assets/telegram_icon.png" alt="Telegram" title="Telegram"/> 
                </a>
                <a className="footer-link" href="/" target="_blank" rel="noreferrer">
                  <img className="rounded-lg" src="/assets/youtube_icon.png" alt="Youtube" title="Youtube"/> 
                </a>
            </div>
            <div className="footer-social hidden">
                <a className="footer-link" href="/" target="_blank" rel="noreferrer" >
                  <img className="rounded-lg" src="/assets/discord_icon.png" alt="Discord" title="Discord" /> 
                </a>
              
                <a className="footer-link" href="/" target="_blank" rel="noreferrer">
                  <img className="rounded-lg" src="/assets/facebook_icon.png" alt="Facebook" title="Facebook"/> 
                </a>
            </div>
            <div className="footer-social hidden">
                <a className="footer-link" href="/" target="_blank" rel="noreferrer" >
                  <img className="rounded-lg" src="/assets/instagram_icon.png" alt="Instagram" title="Instagram"/> 
                </a>
              

                <a className="footer-link" href="/" target="_blank" rel="noreferrer">
                  <img className="rounded-lg" src="/assets/agenda_icon.png" alt="Appointment" title="Schedule an appointment"  /> 
                </a>
              
            </div>
        </div>

        
        
        <div className="footer-section">
          <h3> Company </h3>
          <p className="footer-link ">
            <a className="footer-link" href="https://www.iab.ai/docs/about-us" target="_blank" rel="noreferrer">
              About us
            </a>
          </p>
          <p className="footer-link">
            <a className="footer-link" href="https://admin.iab.ai/public/recruitment/" target="_blank" rel="noreferrer">
              Career
            </a>
          </p>
          <p className="footer-link">
            <a className="footer-link" href="https://www.iab.ai/partners/partner_program" target="_blank" rel="noreferrer">
              Partner program
            </a>
          </p>
        </div>



       <div className="footer-section">
          <h3> Legal </h3>
              <p className="footer-link">
                <a className="footer-link" href="https://www.iab.ai/docs/terms-and-conditions" target="_blank" rel="noreferrer">
                  Terms and Conditions
                </a>
              </p>
          <p className="footer-link">
            <a className="footer-link" href="https://www.iab.ai/docs/privacy-policy" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </p>


              <p className="footer-link">
                <a className="footer-link" href="https://www.iab.ai/docs/iabpay-terms-and-conditions"  target="_blank" rel="noreferrer">
                  IABPAY Terms & Conditions
                </a>
              </p>
              <p className="footer-link">
                <a className="footer-link" href="https://www.iab.ai/partners/partner_agreement" target="_blank"  rel="noreferrer">
                  Partners agreement
                </a>
              </p>

        </div>

        </div>
        </div>
  );
}

export default Footer;
