import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Joi from "joi";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/TextField";
import Button from "@mui/material/Button";
import useForm from "../hooks/useForm";
import { saveUser } from "../services/userService";
import toast from "react-hot-toast";
import Navbar from "./Navbar";
import Footer from "./Footer";


const validationSchema = {
  username: Joi.string().min(3).max(255).required().label("Username"),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email Address"),
  password: Joi.string().min(6).max(255).required().label("Password"),
  password2: Joi.any()
    .valid(Joi.ref("password"))
    .error(() => "Passwords do not match")
    .required(),
};

function Signup() {
  const navigate = useNavigate();
  const {
    values,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    handleFocus,
  } = useForm({
    initialValues: {
      username: "",
      email: "",
      password: "",
      password2: "",
    },
    validationSchema,
    doSubmit,
  });

  async function doSubmit() {
    try {
      const userData = { ...values };
      await saveUser(userData);
      toast.success("Registerd Successfully");
      navigate("/signin");
    } catch (err) {
      if (err?.response?.status >= 400 && err?.response?.status < 500) {
        toast.error(JSON.stringify(err?.response?.data?.errors));
      }
      console.log(err);
    }
  }

  return (
    <>
    <Navbar />
    <div>
       

    <Box
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
    >
      <Box
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        px={3}
        pt={3}
        textAlign="center"
      >
        <Typography variant="h4" fontWeight="medium" mt={1}>
          Join us today
        </Typography>
        <Typography display="block" variant="button" my={1}>
          Enter your email and password to register
        </Typography>
      </Box>
      <Box pt={4} pb={3} px={3}>
        <Box component="form" role="form" onSubmit={handleSubmit}>
          <Box textAlign="center" mb={6}>
            <h1>Signup</h1>
          </Box>
          <Box mb={2}>
            <Input
              type="text"
              label="Name"
              name="username"
              value={values.username}
              error={!!errors.username}
              helperText={errors.username}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              required
              fullWidth
            />
          </Box>
          <Box mb={2}>
            <Input
              type="email"
              label="Email"
              name="email"
              value={values.email}
              error={!!errors.email}
              helperText={errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              required
              fullWidth
            />
          </Box>
          <Box mb={2}>
            <Input
              type="password"
              label="Password"
              name="password"
              value={values.password}
              error={!!errors.password}
              helperText={errors.password}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              required
              fullWidth
            />
          </Box>
          <Box mb={2}>
            <Input
              type="password"
              label="Confirm Password"
              name="password2"
              value={values.password2}
              error={!!errors.password2}
              helperText={errors.password2}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              required
              fullWidth
            />
          </Box>
          <Box mt={4} mb={1}>
            <Button variant="contained" color="info" type="submit" fullWidth>
              Sign up
            </Button>
          </Box>
          <Box mt={3} mb={1} textAlign="center">
            <Typography variant="button" color="text">
              Already have an account?{" "}
              <Typography
                component={Link}
                // to="/auth/signin"
                to="/signin"
                variant="button"
                color="info"
                fontWeight="medium"
              >
                Sign In
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
    </div>
    <Footer />
  </>
  );
}

export default Signup;
