import PropTypes from "prop-types";

import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import NavbarLink from "./NavbarLink";

function NavbarMobile({ open, close }) {
  const { width } = open && open.getBoundingClientRect();

  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      <Box px={0.5}>
        <NavbarLink icon="donut_large" name="home" route="/" />
        <NavbarLink icon="person" name="contact" route="/contact" />
        <NavbarLink icon="account_circle" name="sign up" route="/signup" />
        <NavbarLink icon="key" name="sign in" route="/signin" />
      </Box>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
NavbarMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object])
    .isRequired,
};

export default NavbarMobile;
